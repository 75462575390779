import styled from 'styled-components/macro';
import { commonLinkStyles } from 'atoms/Link';

const AnchorTag = styled.a`
  ${commonLinkStyles}
`;

export interface Props {
  href: string
  noUnderline: boolean
  className?: string
  children: React.ReactNode
  newTab: boolean
}

export default function ExternalLink({ href, noUnderline = false, newTab = false, className, children }: Props) {
  return (
    <AnchorTag
      href={href}
      className={className}
      $noUnderline={noUnderline}
      target={newTab ? '_blank' : undefined}
      rel={newTab ? 'noopener noreferrer' : undefined}
    >
      {children}
    </AnchorTag>
  );
}
