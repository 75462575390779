import { gql, useMutation } from '@apollo/client';
import useNotifications from 'hooks/useNotifications';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import Loader from './Loader';

const CONNECT_INTEGRATION_MUTATION = gql`
  mutation connectSurveyMonkey($code: String!) {
    connectIntegration(type: SurveyMonkey, code: $code)
  }
`;

export default function ConnectSurveyMonkeyAccount() {
  const history = useHistory();
  const notify = useNotifications();
  const params = new URLSearchParams(window.location.search);
  const [connect] = useMutation(CONNECT_INTEGRATION_MUTATION, {
    variables: { code: params.get('code') }
  });

  useEffect(() => {
    connect().then(() => {
      notify({ message: 'SurveyMonkey account connected successfully!' });
      history.push('/studies/surveymonkey');
    });
  }, [connect, history, notify]);

  return <Loader />;
}
