import { useState } from 'react';
import styled from 'styled-components/macro';
import { Route, useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import StudyTile from 'components/StudyTile';
import ErrorPage from 'components/ErrorPage';
import { ContentWrapper, Content } from 'components/atoms/layout';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import Link from 'atoms/Link';
import { useQuery } from '@apollo/client';
import NewStudy from 'components/NewStudy';
import UploadStudy from 'components/UploadStudy';
import TileGrid from 'components/TileGrid';
import Dashboard from 'components/Dashboard';
import WelcomeToAkin from 'components/WelcomeToAkin';
import FullScreenLoader from 'components/FullScreenLoader';
import Select from 'components/Select';
import SurveyMonkey from './SurveyMonkey';
import useAuth from 'hooks/useAuth';
import { FREE_PLAN_STUDY_LIMIT, FREE_PLAN_RESPONSE_LIMIT } from '../constants';
import Button from 'atoms/Button';
import Inline from 'atoms/Inline';
import Stack from 'atoms/Stack';
import Icon from 'atoms/Icon';
import sortBy from 'lodash.sortby';
import useStudyAccessTimes from 'hooks/useStudyAccessTimes';
import { MY_STUDIES_QUERY } from 'queries';

const BlankTile = styled.div`
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => p.theme.colors.accentBackground};
`;

const FreePlanMessage = styled.div`
  padding: 20px;
  border-radius: ${p => p.theme.borderRadius};
  background-color: ${p => p.theme.colors.accentBackground};
  grid-column: 3 / span 2;
  grid-row: 1;

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    grid-column: 1 / span 2;
  }
`;

export default function Studies() {
  const history = useHistory();
  const [studyAccessTimes] = useStudyAccessTimes();
  const impersonatedUserId = new URLSearchParams(window.location.search).get('imp');

  const { roles } = useAuth();
  //const [polling, setPolling] = useState(false);
  const [filter, setFilter] = useState('all');

  const { data, loading, error, refetch } = useQuery(MY_STUDIES_QUERY, {
    variables: {
      userId: impersonatedUserId,
    },
    pollInterval: 0 //polling ? 1000 : 0,
  });

  let studies;

  if (!data) {
    studies = [];
  } else if (filter === 'all') {
    studies = data.myStudies.concat(data.sharedStudies);
  } else {
    studies = data[filter];
  }

  // Poll-refresh this page when one of our studies is in the upload pipeline (just for fun really)
  // TODO: fires way too often (maybe this doesn't really matter)
  // useEffect(() => {
  //   setPolling(studies.find(s => s.state === 'uploadPending' || s.state === 'uploaded'))
  // }, [studies]);

  if (error) return <ErrorPage message={JSON.stringify(error)} />;

  const numPlaceholders = Math.max(0, 9 - (studies.length || 0))
  const onFreePlan = !roles.includes('pro_plan_active');
  const canCreateStudies = !onFreePlan || data?.myStudies.length < FREE_PLAN_STUDY_LIMIT;
  const canSeeSurveyMonkey = roles.includes('admin');

  const sortedStudies = sortBy(studies, s => -(studyAccessTimes[s.id] ?? s.responseCount));

  return (
    <>
      {!loading && (
        <Dashboard invertNav darker>
          <Helmet>
            <title>my studies</title>
          </Helmet>
          <ContentWrapper>
            <Content mobileMaxWidth="350px" >
              <Stack fullWidth spacing="large">
                <Inline fullWidth justify="space-between" align="center" collapseBelow="tablet">
                  <Inline align="center">
                    <Heading>Studies</Heading>

                    <Select
                      background
                      items={[['Owned by anyone', 'all'], ['Owned by me', 'myStudies'], ['Not owned by me', 'sharedStudies']]}
                      selectedValue={filter}
                      onChange={setFilter}
                      fixedWidth={157}
                    />
                  </Inline>

                  <Inline spacing="small">
                    <Button
                      onClick={() => history.push('/studies/new')}
                      disabled={!canCreateStudies}
                    >
                      <Inline spacing="xsmall" align="center">
                        <Icon i="plus" size="medium"/>
                        Create
                      </Inline>
                    </Button>

                    {canSeeSurveyMonkey ? (
                      <Button
                        onClick={() => history.push('/studies/surveymonkey')}
                        disabled={!canCreateStudies}
                      >
                        <Inline spacing="xsmall" align="center">
                          🐒
                        </Inline>
                      </Button>
                    ) : null}

                    <Button
                      variant="secondary"
                      disabled={!canCreateStudies}
                      onClick={() => history.push('/studies/upload')}
                    >
                      <Inline spacing="xsmall" align="center">
                        <Icon i="arrowLeft" rotation={90} size="medium"/>
                        Upload
                      </Inline>
                    </Button>
                  </Inline>
                </Inline>

                <TileGrid featuredTile>
                  {sortedStudies.map(({ id, name, template, state, myAccess, latestAnalysis, responseCount}) => (
                    <StudyTile
                      key={id}
                      access={myAccess}
                      studyId={id}
                      name={name}
                      template={template}
                      state={state}
                      responseCount={responseCount}
                      latestAnalysis={latestAnalysis}
                      refetch={refetch}
                    />
                  ))}

                  {onFreePlan ? (
                    <FreePlanMessage>
                      <Stack fullWidth fullHeight justify="space-between">
                        <Text>
                          You are on the free plan with {FREE_PLAN_STUDY_LIMIT} free studies
                          and {FREE_PLAN_RESPONSE_LIMIT} responses per study.
                        </Text>

                        <Text>
                          <Link allowWrapping to="/account">Upgrade to pro for unlimited studies and better features.</Link>
                        </Text>
                      </Stack>
                    </FreePlanMessage>)
                  : null}

                  {new Array(numPlaceholders).fill(null).map((_, i) => (
                    <BlankTile key={i} />
                  ))}
                </TileGrid>
              </Stack>

              {canSeeSurveyMonkey ? (
                <Route exact path="/studies/surveymonkey">
                  <SurveyMonkey />
                </Route>
              ) : null}

              <Route exact path="/studies/welcome">
                <WelcomeToAkin />
              </Route>

              <Route exact path="/studies/new">
                <NewStudy />
              </Route>

              <Route exact path="/studies/upload">
                <UploadStudy />
              </Route>
            </Content>
          </ContentWrapper>
        </Dashboard>
      )}

      <FullScreenLoader show={loading} />
    </>
  );
}
