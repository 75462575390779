import { Fragment } from 'react';
import { useHistory } from 'react-router';
import { useQuery, useMutation, gql } from '@apollo/client';
import Stack from 'atoms/Stack';
import Modal from 'components/Modal';
import Inline from 'atoms/Inline';
import Heading from 'atoms/Heading';
import Button from 'atoms/Button';
import Loader from './Loader';
import Grid from 'atoms/Grid';
import IconButton from 'atoms/IconButton';
import { MY_STUDIES_QUERY } from 'queries';

const ACTIVE_INTEGRATIONS_QUERY = gql`
  query activeIntegrations {
    me {
      activeIntegrations
    }
  }
`;

const SURVEY_MONKEY_STUDIES_QUERY = gql`
  query surveyMonkeyStudies {
    importableStudies(integration: SurveyMonkey) {
      id
      name
    }
  }
`;

const SURVEY_MONKEY_CLONE_STUDY_MUTATION = gql`
  mutation cloneSurveyMonkeyStudy($id: ID!) {
    importStudy(type: SurveyMonkey, id: $id) {
      id
      name
    }
  }
`;

type ImportableStudy = {
  id: string,
  name: string
}

function redirectToSurveyMonkey() {
  const params = new URLSearchParams({
    redirect_uri: 'http://localhost:8888/integrations/surveymonkey',
    client_id: process.env.REACT_APP_SURVEY_MONKEY_CLIENT_ID!,
    response_type: 'code',
    state: 'testing',
  });
  window.location.href = `https://api.surveymonkey.com/oauth/authorize?${params.toString()}`
}

export default function SurveyMonkey() {
  const history = useHistory();
  // TODO: fetch-policy on these?
  const { loading: integrationsLoading, data: integrationsData } = useQuery(ACTIVE_INTEGRATIONS_QUERY);
  const surveyMonkeyActive = integrationsData?.me.activeIntegrations.includes('SurveyMonkey');

  const { loading: studiesLoading, data: studiesData } = useQuery(SURVEY_MONKEY_STUDIES_QUERY, {
    skip: !surveyMonkeyActive,
  });

  const [importMutation, { loading: importLoading }] = useMutation(SURVEY_MONKEY_CLONE_STUDY_MUTATION, {
    refetchQueries: [{ query: MY_STUDIES_QUERY, variables: { userId: null }}],
    awaitRefetchQueries: true,
  });

  function importStudy(studyId: string) {
    importMutation({
      variables: {
        id: studyId,
      }
    }).then(() => {
      history.push('/studies');
    });
  }

  if (integrationsLoading || studiesLoading || importLoading) {
    return (
      <Modal handleClose={() => history.push('/studies')}>
        <Loader />
      </Modal>
    );
  }

  if (!surveyMonkeyActive) {
    return (
      <Modal handleClose={() => history.push('/studies')}>
        <Stack fullWidth spacing="large">
          <Inline fullWidth justify="center">
            <Heading>Connect to SurveyMonkey</Heading>
          </Inline>

          <Button onClick={redirectToSurveyMonkey}>
            connect the monkey
          </Button>
        </Stack>
      </Modal>
    );
  }

  return (
    <Modal handleClose={() => history.push('/studies')}>
      <Stack fullWidth spacing="large">
        <Inline fullWidth justify="center">
          <Heading>Your SurveyMonkey surveys</Heading>
        </Inline>

          <Grid rowTemplate={["1fr 50px"]}>
            <Grid.Header>Study name</Grid.Header>
            <Grid.Header>Import</Grid.Header>

            {studiesData?.importableStudies.map((s: ImportableStudy) => {
              return (
                <Fragment key={s.id}>
                  <Grid.Cell>{s.name}</Grid.Cell>
                  <Grid.Cell align="right">
                    <IconButton icon="clone" onClick={() => importStudy(s.id)} />
                  </Grid.Cell>
                </Fragment>
              );
            })}
          </Grid>
        </Stack>
      </Modal>
    );
}
