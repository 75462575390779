import { gql } from '@apollo/client';

const STUDY_FRAGMENT = gql`
  fragment StudyFragment on Study {
    id
    slug
    template
    myAccess
    state
    name
    userId
    createdAt
    updatedAt
    questions {
      id
      type
      label
      allowOther
      options {
        label
        exclude
      }
    }
    options {
      accentColor
      typography
      redirectUrl
    }
  }
`;

export const RESPONSE_FRAGMENT = gql`
  fragment ResponseFragment on Response {
    id
    num
    identifier
    email
    furtherResearch
    createdAt
    answers
    highlights
    removedHighlights
  }
`;

const STUDY_RESULTS_FRAGMENT = gql`
  fragment StudyResultsFragment on Study {
    responses {
      ...ResponseFragment
    }
    analysisOptions {
      ignoredParticipantIds
      ignoredQuestionIds
      numClusters
    }
    resultsOptions {
      clusterNames
    }
    latestAnalysis {
      id
      createdAt
      state
      personas {
        personaId
        name
      }
      participants {
        id
        num
        personaId
        highlights
        x
        y
      }
      questionImportance
    }
  }

  ${RESPONSE_FRAGMENT}
`;

export const MY_STUDIES_QUERY = gql`
  query dashboardStudies($userId: ID) {
    myStudies(userId: $userId) {
      id
      name
      template
      state
      responseCount
      myAccess
      latestAnalysis {
        id
        state
        participants {
          id
          personaId
          x
          y
        }
        personas {
          personaId
        }
      }
    }
    sharedStudies {
      id
      name
      template
      state
      responseCount
      myAccess
    }
  }
`;

export const STUDY_QUERY = gql`
  query studyQuery($id: ID, $slug: ID) {
    study(id: $id, slug: $slug) {
      ...StudyFragment
    }
  }

  ${STUDY_FRAGMENT}
`;

export const STUDY_RESULTS_QUERY = gql`
  query studyQuery($id: ID, $slug: ID) {
    study(id: $id, slug: $slug) {
      id,
      ...StudyFragment
      ...StudyResultsFragment
    }
  }

  ${STUDY_FRAGMENT}
  ${STUDY_RESULTS_FRAGMENT}
`;

export const STUDY_PERMISSIONS_QUERY = gql`
  query studyPermissions($id: ID!) {
    study(id: $id) {
      id
      permissions {
        userId
        email
        access
        createdAt
      }
    }
  }
`;

export const UPDATE_STUDY_MUTATION = gql`
  mutation updateStudy($id: ID!, $input: StudyInput!) {
    updateStudy(id: $id, input: $input) {
      ...StudyFragment
    }
  }

  ${STUDY_FRAGMENT}
`;

export const UPDATE_ACTIVE_STUDY_MUTATION = gql`
  mutation updateStudy($id: ID!, $input: StudyInput!) {
    updateStudy(id: $id, input: $input) {
      ...StudyFragment
      ...StudyResultsFragment
    }
  }

  ${STUDY_FRAGMENT}
  ${STUDY_RESULTS_FRAGMENT}
`;

export const CREATE_STUDY_MUTATION = gql`
  mutation createStudy($name: String!, $template: StudyTemplate!, $cloneStudyId: ID) {
    createStudy(name: $name, template: $template, cloneStudyId: $cloneStudyId) {
      ...StudyFragment
    }
  }

  ${STUDY_FRAGMENT}
`;

export const PUBLISH_MUTATION = gql`
  mutation publishMutation($id: ID!) {
    publishStudy(id: $id) {
      id
      state
    }
  }
`;

export const INVITE_USER_MUTATION = gql`
  mutation inviteUserToStudyMutation($studyId: ID!, $email: String!, $access: StudyAccessLevel!) {
    inviteUserToStudy(studyId: $studyId, email: $email, access: $access) {
      permissions {
        userId
        email
        access
        createdAt
      }
    }
  }
`;

export const CHANGE_USER_ACCESS_MUTATION = gql`
  mutation changeUserAccessForStudyMutation($studyId: ID!, $userId: ID!, $access: StudyAccessLevel) {
    changeUserAccessForStudy(studyId: $studyId, userId: $userId, access: $access) {
      ...StudyFragment
    }
  }

  ${STUDY_FRAGMENT}
`;


export const REQUEST_UPLOAD_URL_MUTATION = gql`
  mutation requestUploadUrl($name: String) {
    requestUploadUrl(name: $name) {
      url
      study {
        id
      }
    }
  }
`;

export const LOG_IN_MUTATION = gql`
  mutation login($email: String!, $otp: String!) {
    loginUser(email: $email, otp: $otp)
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation refreshToken {
    refreshToken
  }
`;

export const MANAGE_SUBSCRIPTION_MUTATION = gql`
  mutation manageSubscription {
    manageSubscription
  }
`;

export const REQUEST_OTP_MUTATION = gql`
  mutation requestOtp($email: String!) {
    requestOtp(email: $email)
  }
`;

export const REQUEST_CLUSTERS = gql`
  mutation requestAnalysis($studyId: ID!, $numClusters: Int!) {
    requestAnalysis(studyId: $studyId, numClusters: $numClusters) {
      id
      state
    }
  }
`;

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    name
    settings {
      marketingOptIn
    }
    plan {
      type
      subscribedUntil
    }
  }
`;

export const USER_INFO_QUERY = gql`
  query me {
    me {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($input: UserInput!) {
    updateMe(input: $input) {
      user {
        ...UserFragment
      }
      jwt
    }
  }

  ${USER_FRAGMENT}
`;

export const SIGN_UP_MUTATION = gql`
  mutation signUp($email: String!, $name: String, $marketingOptIn: Boolean) {
    createUser(email: $email, name: $name, marketingOptIn: $marketingOptIn) {
      user {
        ...UserFragment
      }
      jwt
    }
  }

  ${USER_FRAGMENT}
`;
