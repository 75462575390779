import { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { Stack, Inline, Spacer } from 'components/atoms/layout';
import Button from 'atoms/Button';
import { BorderlessInput } from 'components/atoms/inputs';
import { useMutation } from '@apollo/client';
import { SIGN_UP_MUTATION } from 'queries';
import Text from 'atoms/Text';
import Heading from 'atoms/Heading';
import ExternalLink from 'atoms/ExternalLink';
import { ReactComponent as EmailSvg } from 'images/icons/email.svg';
import Modal from 'components/Modal';
import Checkbox from 'components/Checkbox';
import ValidatedField from 'components/ValidatedField';
import { ReactComponent as ClusterSvg } from 'images/cluster-map-demo.svg';
import FunctionLink from 'atoms/FunctionLink';

function SignupPage({ handleClose, toLogin }) {
  const { isLoggedIn, setToken } = useAuth();
  const history = useHistory();
  const [mode, setMode] = useState('email');
  const [error, setError] = useState(null);
  const [signupEmail, setSignupEmail] = useState('');
  const [tosChecked, setTosChecked] = useState(false);
  const [marketingOptIn, setMarketingOptIn] = useState(false);

  const [mutation, { loading }] = useMutation(SIGN_UP_MUTATION, {
    onCompleted: response => {
      setToken(response.createUser.jwt);
      history.push('/studies/welcome');
    },
    onError: e => {
      if (e.message === 'instance not unique') {
        setError('Email address already signed up');
      } else {
        setError(e.message);
      }
    }
  });

  if (isLoggedIn) {
    return <Redirect to="/studies" />;
  }

  const handleSignup = () => {
    if (!signupEmail.match(/@/) || signupEmail.match(/\s/)) {
      setError('Enter a valid email address');
    } else {
      mutation({ variables: { email: signupEmail.toLowerCase(), marketingOptIn } })
    }
  };

  if (mode === 'welcome') {
    return (
      <Modal handleClose={handleClose}>
        <Stack alignItems="center">
          <Heading>Welcome</Heading>
          <Text size="body">
            Create meaningful data-driven personas. Enhance UX with AI. All with gorgeous visualisations and surveys.
          </Text>

          <Stack>
            <Button variant="secondary" onClick={() => setMode('email')}>
              <EmailSvg />
              Sign up via email
            </Button>
          </Stack>

          <Text size="body">
            Already have an account? <FunctionLink onClick={toLogin}>Log in</FunctionLink>
          </Text>
        </Stack>
      </Modal>
    );
  }

  if (mode === 'email') {
    return (
      <Modal thin handleClose={handleClose}>
        <Stack spacing="small">
          <Heading>Start using akin</Heading>

          <Text>Create surveys designed to segment your customers instantly with AI</Text>

          <Spacer size="small" />

          <Inline fullWidth justify="center">
            <ClusterSvg width="214" height="180" />
          </Inline>

          <Spacer size="small" />

          <ValidatedField label="Email address" error={error}>
            <BorderlessInput
              fullWidth
              autoFocus
              value={signupEmail}
              onChange={e => setSignupEmail(e.target.value)}
            />
          </ValidatedField>

          <Stack spacing="xsmall">
            <Checkbox
              checked={marketingOptIn}
              onChange={v => setMarketingOptIn(v)}
            >
              <Text family="marketing" size="small">
                I would like to receive marketing communications.
              </Text>
            </Checkbox>

            <Checkbox
              checked={tosChecked}
              onChange={v => setTosChecked(v)}
            >
              <Text family="marketing" size="small">
                I agree to the{' '}
                <ExternalLink newTab href="/policies/akin_privacy_policy_july_2020.pdf">privacy policy</ExternalLink>
                {' '}and{' '}
                <ExternalLink newTab href="/policies/akin_terms_of_use_july_2020.pdf">terms of use</ExternalLink>
              </Text>
            </Checkbox>
          </Stack>

          <Button variant="secondary" onClick={handleSignup} disabled={!tosChecked} loading={loading}>
            Sign up
          </Button>
        </Stack>
      </Modal>
    );
  }

  if (mode === 'pending') {
    return (
      <Modal thin handleClose={handleClose}>
        <Stack>
          <Heading>Check your inbox</Heading>
          <Text size="body">
            We just emailed a sign in link to{' '}
            <Text as="span" strong>{signupEmail}</Text>.
          </Text>
        </Stack>
      </Modal>
    );
  }

  // Shouldn't normally happen, but as a failsafe!
  return <Redirect to="/" />;
}

export default SignupPage;
