import styled from 'styled-components/macro';
import { Spacer, NotMobile, MobileOnly, InlineCollapse, DesktopOnly } from 'components/atoms/layout';
import Banner from 'components/marketing/Banner';

import { MarketingContent, PageTitle, CtaButton, CallToAction, Emphasis, LargeImage } from 'components/atoms/marketing';
import MarketingComponent from 'components/marketing/MarketingComponent';
import heroImage from 'images/marketing/hero_image.png';
import Text from 'atoms/Text';
import Stack from 'atoms/Stack';
import Inline from 'atoms/Inline';

const HeroImage = styled.img`
  @media (min-width: ${p => p.theme.breakpoints.tablet}) {
    max-width: 428px;
    max-height: 318px;
  }
`;

// DRY this up with MarketingPage too
function addEmphasis(string) {
  const [head, emph, tail] = string.split('*');

  if (!emph) { return head; }

  return <>{head} <Emphasis>{emph}</Emphasis> {tail}</>;
}

export default function Overview({ triggerSignup, blocks }) {
  return (
    <>
      <NotMobile><Spacer size="xlarge" /></NotMobile>

      <Stack fullWidth spacing="none" mobileSpacing="small">
        <MarketingContent>
          <InlineCollapse fullWidth spacing="medium">
            <Stack fullWidth spacing="large">
              <Stack spacing="medium">
                <PageTitle>
                  Replace <Emphasis>personas</Emphasis> with one survey
                </PageTitle>

                <Text size="xlarge">
                  Create a survey, share it with customers, and instantly create data-driven segments with AI.
                </Text>
              </Stack>

              <NotMobile>
                <Inline align="center">
                  <CtaButton variant="primary" onClick={triggerSignup}>Sign up for free</CtaButton>
                  <CallToAction variant="text" to="/demo" target="_blank">See the demo</CallToAction>
                </Inline>
              </NotMobile>

              <MobileOnly>
                <Inline fullWidth justify="center">
                  <CtaButton onClick={triggerSignup}>Sign up for free</CtaButton>
                </Inline>
              </MobileOnly>

              <Spacer/>
            </Stack>

            <DesktopOnly>
              <HeroImage src={heroImage} alt="Computer with stylized clusters" />
            </DesktopOnly>
          </InlineCollapse>
        </MarketingContent>

        {blocks.map((block, index) => (
          // DRY this up, it's duplicated in MarketingPage
          block.body ? (
            <MarketingComponent
              key={index}
              altBackground={index % 2 === 0}
              imageOnLeft={index === 0}
              title={addEmphasis(block.title)}
              text={block.body}
              image={block.image}
              imageAlt={block.imageAlt}
              ctaLabel={block.ctaText}
              ctaLink={block.ctaTarget}
            />
          ) : (
            <LargeImage src={block.image} alt={block.imageAlt} />
          )
        ))}
      </Stack>

      <NotMobile style={{ width: '100%' }}><Banner triggerSignup={triggerSignup} /></NotMobile>
    </>
  );
}
