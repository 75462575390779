import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import useNotifications from '../hooks/useNotifications';
import { LOG_IN_MUTATION } from 'queries';
import Loader from 'components/Loader';
import useAuth from 'hooks/useAuth';
import ErrorPage from 'components/ErrorPage';

export default function Login() {
  const { isLoggedIn, setToken } = useAuth();
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const email = queryParams.get('email');
  const otp = queryParams.get('otp');
  const notify = useNotifications();
  const [login, { called, data, loading, error }] = useMutation(LOG_IN_MUTATION, {
    onError: e => {
      if (e.message.match(/expire/)) {
        notify({ message: `Magic link expired, try logging in again` });
      } else {
        notify({ message: `There was an error logging in` });
      }
    },
  });

  useEffect(() => {
    if (!isLoggedIn && email && otp) {
      login({ variables: { email, otp }})
        .then(response => {
          if(response) {
            if (response.data.loginUser) {
              setToken(response.data.loginUser);
              history.push('/studies/welcome');
            } else {
              console.error('hmm');
            }
          }
        });
    }
  }, [isLoggedIn, history, email, otp, login, setToken]);

  if(isLoggedIn) return <Redirect to="/studies" />;
  if(!email || !otp) return <Redirect to="/" />;
  if (!called || loading) return <Loader />;
  if (error) return <ErrorPage message={error.message} />;

  if (!data.loginUser) return <ErrorPage message="Login failed. Your token may have expired." />;

  return <Redirect to="/" />;
}
