import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { MarketingContent, PageTitle, Emphasis } from 'components/atoms/marketing';
import { Expander, Inline } from 'components/atoms/layout';
import Button from 'atoms/Button';
import Text from 'atoms/Text';
import useAuth from 'hooks/useAuth';
import { loadStripe } from '@stripe/stripe-js';
import { FREE_PLAN_STUDY_LIMIT, FREE_PLAN_RESPONSE_LIMIT } from '../../constants';
import Heading from 'atoms/Heading';
import Stack from 'atoms/Stack';
import ClusterBackground from 'components/marketing/ClusterBackground';
import apiCall from 'utils/apiCall';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PlanGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 400px));
  grid-column-gap: 34px;
  padding-bottom: 50px;

  @media (max-width: ${p => p.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const PlanFeatureGrid = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 17.5px;
  align-items: center;
  font-size: 20px;

  > svg {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    color: ${p => p.theme.colors.brand};
  }
`;

const PlanCard = styled.div`
  width: 100%;
  padding: 32px 49px 42px 49px;
  margin-bottom: 50px;
  background-color: ${p => p.theme.colors.accentBackground};
  box-shadow: ${p => p.theme.shadows.large};
  border-radius: ${p => p.theme.borderRadius};
`;

export default function Pricing({ triggerSignup }) {
  const { isLoggedIn, id, email } = useAuth();

  const handleSignup = async () => {
    if (id) {
      const stripe = await stripePromise;
      const response = await apiCall('checkout', { method: 'POST', body: JSON.stringify({ userId: id, email: email }) });
      const session = await response.json();
      const result = await stripe.redirectToCheckout({ sessionId: session.id });

      if (result.error) {
        // TODO: show this error somehow
        console.error(result.error);
      }
    } else {
      // If they're not logged in don't try to sub them
      triggerSignup();
    }
  }

  return (
    <>
      <MarketingContent>
        <Stack fullWidth spacing="medium">
          <ClusterBackground>
            <Stack>
              <PageTitle>
                Our <Emphasis>pricing</Emphasis>
              </PageTitle>

              <Text size="xlarge" style={{ maxWidth: '40ch' }}>
                We only charge for storing data and running algorithms. No micro-transactions or complicated pricing structures.
              </Text>
            </Stack>
          </ClusterBackground>

          <PlanGrid>
            <PlanCard>
              <Stack fullHeight spacing="medium">
                <Stack spacing="small">
                  <Heading>Free</Heading>

                  <Inline spacing="small">
                    <Text size="large">
                      <strong>$0</strong>
                      {' '}monthly
                    </Text>
                  </Inline>

                  <Text size="body">
                    Get started, run a personal study or trial for your business.
                  </Text>
                </Stack>

                <PlanFeatureGrid>
                  <Text strong>{FREE_PLAN_STUDY_LIMIT}</Text>
                  <Text>Studies</Text>

                  <Text strong>{FREE_PLAN_RESPONSE_LIMIT}</Text>
                  <Text>Responses per survey</Text>

                  <Text strong>{FREE_PLAN_RESPONSE_LIMIT}</Text>
                  <Text>Responses per upload</Text>
                </PlanFeatureGrid>

                <Expander />

                { isLoggedIn
                  ? <Button variant="secondary" as={Link} to="/account">Choose free</Button>
                  : <Button variant="secondary" onClick={triggerSignup}>Choose free</Button>
                }
              </Stack>
            </PlanCard>

            <PlanCard>
              <Stack fullHeight spacing="medium">
                <Stack spacing="small">
                  <Heading>Pro</Heading>
                  <Inline spacing="small">
                    <Text size="large">
                      <strong>$89</strong>
                      {' '}monthly
                    </Text>
                  </Inline>

                  <Text size="body">
                    Best for businesses looking to run multiple in-depth surveys.
                  </Text>
                </Stack>

                <PlanFeatureGrid>
                  <Text strong>∞</Text>
                  <Text>Unlimited studies</Text>

                  <Text strong>1000</Text>
                  <Text>Responses per survey</Text>

                  <Text strong>1000</Text>
                  <Text>Responses per upload</Text>
                </PlanFeatureGrid>

                <Expander />

                <Button onClick={handleSignup}>Choose pro</Button>
              </Stack>
            </PlanCard>
          </PlanGrid>

          <ClusterBackground center>
            <Heading>Want enterprise solutions?</Heading>
            <Button onClick={() => window.location = 'mailto:hello@akin.nz'}>
              Contact us
            </Button>
          </ClusterBackground>
        </Stack>
      </MarketingContent>
    </>
  );
}
