import styled from 'styled-components/macro';
import { commonLinkStyles } from 'atoms/Link';

const AnchorTag = styled.a`
  ${commonLinkStyles}

  height: fit-content;
`;

export interface Props {
  onClick: () => void
  noUnderline?: boolean
  className?: string
  children: React.ReactNode
}

export default function FunctionLink({ onClick, noUnderline = false, className, children }: Props) {
  return (
    <AnchorTag
      onClick={onClick}
      className={className}
      $noUnderline={noUnderline}
    >
      {children}
    </AnchorTag>
  );
}
