import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, Redirect } from 'react-router-dom';
import useLocalStorage from 'hooks/useLocalStorage';
import { Stack, Inline, Button, Text, TextInput } from 'atoms';
import { CREATE_STUDY_MUTATION } from 'queries';
import Heading from 'atoms/Heading';
import useNotifications from 'hooks/useNotifications';
import Modal from 'components/Modal';
import { MY_STUDIES_QUERY } from 'queries';

export default function NewStudy() {
  const history = useHistory();
  const notify = useNotifications();
  const [, setLastVisitedSection] = useLocalStorage('lastVisitedSetion', 'introduction');
  const [studyName, setStudyName] = useState('');

  const mode = 'name';
  const studyTemplate = 'survey';

  const [createNewStudy, { loading }] = useMutation(CREATE_STUDY_MUTATION, {
    refetchQueries: [{ query: MY_STUDIES_QUERY, variables: { userId: null }}],
    onCompleted: data => {
      setLastVisitedSection('introduction');
      history.push(`/study/${data.createStudy.id}`);
    },
    onError: ({ message }) => notify({ message }),
  });

  const createStudy = () => {
    createNewStudy({
      variables: { name: studyName, template: studyTemplate }
    });
  }

  if (mode === 'name') {
    return (
      <Modal handleClose={() => history.push('/studies')}>
        <Stack fullWidth spacing="large">
          <Inline fullWidth justify="center">
            <Heading>Name your survey</Heading>
          </Inline>

          <Stack fullWidth spacing="xsmall">
            <Text size="small">Survey name</Text>
            <TextInput
              borderless
              autoFocus
              value={studyName}
              onChange={v => setStudyName(v)}
              onEnter={createStudy}
            />
          </Stack>

          <Button variant="secondary" onClick={createStudy} loading={loading} >
            Create
          </Button>
        </Stack>
      </Modal>
    );
  }

  // Shouldn't happen, but as a failsafe!
  return <Redirect to="/studies" />;
}
