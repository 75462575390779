import { generateId } from 'utils/generateId';
import { QUESTION_TYPES } from './constants';
import { arrayMoveImmutable } from 'array-move';

export default function studyReducer(state, action) {
  let newQuestion, oldQuestion;

  switch (action.type) {
    case 'initialize':
      return { ...state, ...action.study };
    case 'changeState':
      return { ...state, state: action.state };
    case 'rename':
      return { ...state, name: action.name };
    // TODO: calling these options as well is v confusing
    case 'setOption':
      return { ...state, options: { ...state.options, [action.option]: action.value }};
    case 'addQuestion':
      const { defaultQuestion, defaultOptions } = QUESTION_TYPES[action.questionType];

      newQuestion = {
        id: generateId('q'),
        type: action.questionType,
        label: defaultQuestion || '',
        options: null,
        allowOther: false,
      };

      if (defaultOptions) {
        newQuestion.options = defaultOptions.map(label => ({ label, exclude: null }));
      }

      return { ...state, questions: [ ...state.questions, newQuestion]};
    case 'moveQuestion':
      const newQuestions = arrayMoveImmutable(state.questions, action.from, action.to);
      return { ...state, questions: newQuestions };
    case 'duplicateQuestion':
      oldQuestion = state.questions.find(q => q.id === action.id);

      if (!oldQuestion) { throw new Error(`Can't duplicate ID ${action.id} that does not exist`); }

      newQuestion = { ...oldQuestion, id: generateId('q') }; // TODO: does this dup options correctly?

      return { ...state, questions: [ ...state.questions, newQuestion]};
    case 'removeQuestion':
      return { ...state, questions: state.questions.filter(q => q.id !== action.id) };
    case 'changeLabel':
      return { ...state, questions: state.questions.map(q => (
        q.id === action.id ? { ...q, label: action.value } : q
      ))};
    case 'changeQuestionType':
      return { ...state, questions: state.questions.map(q => (
        q.id === action.id ? { ...q, type: action.newType } : q
      ))};
      case 'changeQuestionAllowOther':
        return { ...state, questions: state.questions.map(q => (
          q.id === action.id ? { ...q, allowOther: action.value } : q
        ))};
    case 'addOption':
      return { ...state, questions: state.questions.map(q => (
        q.id === action.id ? { ...q, options: [...q.options || [], { label: action.value, exclude: null }] } : q
      ))};
    case 'changeOption':
      return { ...state, questions: state.questions.map(q => (
        q.id === action.id ? {
          ...q,
          options: q.options.map((o,i) => (
            i === action.optionIndex ? { ...o, label: action.value } : o
          ))} : q
      ))};
    case 'removeOption':
      return { ...state, questions: state.questions.map(q => (
        q.id === action.id ? { ...q, options: q.options.filter((o,i) => i !== action.optionIndex) } : q
      ))};
    case 'changeOptionExclude':
      return { ...state, questions: state.questions.map(q => (
        q.id === action.id ? {
          ...q,
          options: q.options.map((o,i) => (
            i === action.optionIndex ? { ...o, exclude: action.value } : o
          ))} : q
      ))};
    default:
      throw new Error(`Unknown action in studyReducer: ${action.type}`);
  }
}
